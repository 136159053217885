import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MetroSpinner } from "react-spinners-kit";
import LoadingOverlay from 'react-loading-overlay';
import { IRecipientSignatureDetails, SignatureDocumentStatus, DocumentStatus, StampingStatus } from '../../Core/Domain/ViewModels/ClientSignatureStatus';
import * as PublicStore from '../../store/Common/PublicStore';
import * as LoaderStore from '../../store/Common/LoaderStore';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export type PublicLoginProps =
    {
        publicUserToken: PublicStore.IPublicUserTokenData,
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData,
        requestClientSignatureStatus: (clientGuid: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void) => void
    }
    //  & typeof OtpStore.actionCreators
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>


export class PublicLogin extends React.Component<PublicLoginProps, {}>{

    componentDidMount() {
        let param: any = this.props.match.params;
        if (!this.props.publicUserToken.token) {
            this.props.getTokenForAnonymous(param.Id,
                (clientId: string) => {
                    this.props.requestClientSignatureStatus(clientId, this.handleRedirect) 
                });
        }
    }

    private handleRedirect = (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => {


        if (!ignoreStatus && status == undefined
            || (Object.keys(status).length === 0 && status.constructor === Object)
            || (status && 
                (status.documentStatus === DocumentStatus.Deleted ||
                status.documentStatus === DocumentStatus.SignatureStampingFailed ||
                status.documentStatus === DocumentStatus.Processing ||
                status.documentStatus === DocumentStatus.DeliveryFailed ||
                status.documentStatus === DocumentStatus.None))
        ) {
            localStore.remove('loggedIn');
            this.props.history.push('/invalid');
        }
        else if (status) {
            if (status.documentStatus === DocumentStatus.Cancelled) {
                this.props.history.push('/cancelled/' + clientId);
            }
            else if (status.documentStatus === DocumentStatus.Declined) {
                this.props.history.push('/declined/' + clientId);
            }
            else if (status.isExpired) {
                this.props.history.push('/expired/' + clientId);
            }
            else if (status.stampingStatus === StampingStatus.Completed || 
                    status.isAllSigned ||
                    status.documentStatus === DocumentStatus.ESigned) {
                this.props.history.push('/download/' + clientId);
            }
            else {
                if (status.stampingStatus === StampingStatus.Processing ||
                    status.stampingStatus === StampingStatus.Failed) {
                    this.props.history.push('/signingprogress');
                }
                else
                    this.props.history.push('/esign/' + clientId);
            }
            history.pushState(null, "", location.href);
            window.onpopstate = () => {
                if(window.navigator && window.navigator.userAgent.match('CriOS') && !location.pathname.match("signingsuccess")){
                    location.reload();
                }
                else{
                    history.go(1);
                }
                document.title=("SafeSend Signatures")
            }
        }
    }

    public render() {
        return (
            <LoadingOverlay
                active={this.props.publicUserToken.isLoading || this.props.loaderData.loading}
                spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.publicUserToken.isLoading || this.props.loaderData.loading} />}>
                <div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-page">

                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}