import { ApplicationState } from "../../store/index";
import { SigningSuccess } from "./SigningSuccess";
import { connect } from 'react-redux'; 
import { actionCreators as PublicStore } from '../../store/Common/PublicStore';

function mapStateToProps(state: ApplicationState) {
    return {
        publicData: state.publicData,
        loaderData: state.loaderData
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string) => dispatch(PublicStore.requestPrimaryDetails(Id))        
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SigningSuccess);

 