import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FileSignatureDataViewModel } from '../../Core/Domain/ViewModels/DocumentViewModel';
import * as PublicStore from '../../store/Common/PublicStore';
import * as SignerStore from '../../store/Signing/SignerStore';
import * as OtpStore from '../../store/Account/OtpStore';
import * as DownloadStore from '../../store/Download/DownloadStore';
import { SiriusNotifier } from '../Common/Notification/SiriusNotifier';
import { PdfView } from '../Common/PdfView/PdfView';
import { DeclineRemarks } from './DeclineRemarks';
import { AssignToDelegatee } from './AssignToDelegatee';
import { Footer } from '../Layout/Footer';
import { Main } from '../Layout/Main';
import { IDocument } from '../../Core/Domain/ViewModels/Document';
import { DocumentAdapter } from '../../Core/Services/Adapters/ControlDataAdapter/ControlDataAdapter';
import { ISignerControlDataModal } from '../../Core/Domain/ViewModels/SignerControlDataModal';
import { EsignManager, IEsignValidationModel } from './Base/EsignBase';
import { DownloadStep } from '../../../src/Core/Common/Enums';
import { initialClientSignatureStatus, RecipientType } from '../../Core/Domain/ViewModels/ClientSignatureStatus';
import * as LoaderStore from '../../store/Common/LoaderStore';
import { inMemoryToken } from '../../Core/Services/DataAccess/DataService.Axios'
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { LogoutPopover } from '../Account/Logout/LogoutPopover';
import { ClientTypes } from '../../../src/Core/Common/Enums';
import { logger } from '../../routes';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import {hideFinishButton, isStringNullOrEmpty} from "../Helper/HelperFunctions";
import { SigningConstants } from '../Constants';
import { ISignFinishedResponse } from '../../Core/Domain/ViewModels/ISignFinishedResponse';
import { ButtonComponent } from 'cp-common-ui-components';
import { DelegateIcon } from '../Svg/CustomSvgIcons';


export const NO_INDEX = -1;
const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

interface EsignState {
    showDeclineModal: boolean,
    showAssignToSomeoneElseModal: boolean,
    documentControlsAndData: FileSignatureDataViewModel[],
    showTimeout: boolean,
    finishEnabled: boolean,
    isAssignedToDelegatee: boolean;
    showLogoutDialog: boolean;
    delegateeSendEnabled: boolean;
    declineInProgress: boolean;
}

type ESignProps =
    {
        signerData: SignerStore.ISignerData,
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData
    }
    & typeof SignerStore.actionCreators
    & typeof PublicStore.actionCreators
    & typeof OtpStore.actionCreators
    & typeof DownloadStore.actionCreators
    & RouteComponentProps<{}>

export class Esign extends React.Component<ESignProps, EsignState> {

    private _pdfView: any;

    constructor(props: any) {
        super(props);
        this.state = {
            showDeclineModal: false,
            showAssignToSomeoneElseModal: false,
            documentControlsAndData: [],
            showTimeout: false,
            finishEnabled: false,
            isAssignedToDelegatee: false,
            showLogoutDialog: false,
            delegateeSendEnabled: true,
            declineInProgress: false
        }
    }


    componentDidMount() {
        let param: any = this.props.match.params;
        if (this.props.signerData.SignatureStatus != initialClientSignatureStatus) {
            this.props.requestDocumentControls(param.clientId,
                this.props.signerData.AssignToDelegateeSigner);
        }
        else {
            this.props.requestSignatureInfo(param.clientId, () => {
                this.props.requestDocumentControls(param.clientId,
                    this.props.signerData.AssignToDelegateeSigner);
            });
        }

        if (!isStringNullOrEmpty(this.props.signerData.SignatureStatus.delegateeInfo.EmailId))
        {
            this.setState({ finishEnabled: true });
        }
        logger.trackPageView("Document Esign Page");
    }


    static getDerivedStateFromProps(nextProps: ESignProps, nextState: EsignState) {
        if (nextProps.signerData.DocumentDetails &&
            nextProps.signerData.DocumentDetails.length !== nextState.documentControlsAndData.length)
        {
            return { showDeclineModal: nextState.showDeclineModal, documentControlsAndData: nextProps.signerData.DocumentDetails}
        }
        else {
            return { showDeclineModal: nextState.showDeclineModal, documentControlsAndData: nextState.documentControlsAndData };
        }
    }

    handleDocumentSignFinish = (documentControlData: FileSignatureDataViewModel) => {
        let documentDataList: FileSignatureDataViewModel[] = [...this.state.documentControlsAndData];

        const index = documentDataList.findIndex(x => x.fileId === documentControlData.fileId);
        if (index == NO_INDEX) {
            documentDataList.push(documentControlData);
        }
        else {
            documentDataList[index] = documentControlData;
        }
        this.setState({ documentControlsAndData: documentDataList });
    }

    handleFinish = () => {
        const param: any = this.props.match.params;

        if (this.state.isAssignedToDelegatee || this.props.signerData.AssignToDelegateeSigner) {
            this.setState({ showLogoutDialog: true });
            return false;
        }

        const documentData: IDocument[] = this._pdfView.getDocumentsData();
        const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

        if (validation.status) {
            const TraceEvent: IEventTelemetry = {
                name: "Finish Signing",
                properties: { count: 1 }
            };
            logger.trackEvent(TraceEvent);
            let signerControlDataModal: ISignerControlDataModal = DocumentAdapter.create().convertToServerModel(documentData);
            signerControlDataModal.recipientId = param.clientId;
            hideFinishButton(); //Updation of Remove Finish button is issue in Awesome-pdf-viewer this function is just for hiding the Finsh button for the bug-72107 
            this.setState({ finishEnabled: false });
            this.props.sign(signerControlDataModal, this.onSigningCallback);
        }
        else {
            this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
        }
    }

    onLogout = () => {
        if (this.props.match && this.props.history) {
            let param: any = this.props.match.params;
            inMemoryToken.delete(param.clientId);
            localStore.remove('loggedIn');
            this.setState({
                showLogoutDialog: false,
                isAssignedToDelegatee: false
            });
            this.props.history.push('/logout/' + param.clientId);
        }
    }

    onCancelLogout = () => {
        this.setState({ showLogoutDialog: false });
    }

    onSigningCallback = (signFinishResponse: ISignFinishedResponse) => {
        const param: any = this.props.match.params;
        if (signFinishResponse.isSignFinish === true) {
            this.props.refreshToken(param.clientId);
            this.props.history.push('/signingsuccess/' + param.clientId);
        }
        else if(signFinishResponse.signFinishMessage === SigningConstants.DocumentAlreadySigned){
            SiriusNotifier.Warning(signFinishResponse.signFinishMessage,"");
        }
        else {
            SiriusNotifier.Error("Signing process failed!", "");
        }
    }

    handleDeclineRemarkModalCancel = () => {
        this.setState({ showDeclineModal: false });
    }

    handleDocumentSignDeclined = () => {
        this.setState({ showDeclineModal: true });
    }

    handleDisableNextButton = (value: boolean) => {
        if (this.state.finishEnabled)  this.setState({ finishEnabled: !value});
    }

    onDocumentAssignedToSomeoneElse = () => {
        this.setState({ showAssignToSomeoneElseModal: true });
    }

    private onDecline = (remarks: string) => {
        let param: any = this.props.match.params;
        this.setState({ declineInProgress: true },() =>{
            this.props.declineSigning(param.clientId, remarks, this.onDeclineCallBack);
        });
        logger.trackTrace("onDecline with parameters" + JSON.stringify(param));
    }

    private onDelegationCancelled = () => {
        let param: any = this.props.match.params;
        this.props.delegationCancelled(param.clientId, this.onDelegationCallBack);
        logger.trackTrace("onDelegationCancelled with parameters" + JSON.stringify(param));
    }

    private onAssignToDelegatee = (email: string, name: string, reason: string) => {
        let param: any = this.props.match.params;
        this.setState({ delegateeSendEnabled: false });
        this.props.assignToDelegatee(param.clientId, email, name, reason, this.onAssignToDelegateeCallBack);
        logger.trackTrace("onAssignToDelegatee with parameters" + JSON.stringify({ email: email, name: name}));
    }

    private onDelegationCallBack = (status: boolean) => {
        if (status === true) {
            SiriusNotifier.Success("Delegation cancelled successfully!", "");
            this.setState({
                finishEnabled: false,
                isAssignedToDelegatee: false,
                delegateeSendEnabled: true
            });
        }
        else {
            SiriusNotifier.Warning("Cannot cancel delegation", "")
            this.setState({
                finishEnabled: true,
                delegateeSendEnabled: true
            });
        }

    }

    private onReAssignToDelegatee = (email: string, name: string, reason: string) => {
        let param: any = this.props.match.params;
        this.setState({ delegateeSendEnabled: false });
        this.props.updateDelegatedSigner(param.clientId, email, name, reason, this.onReAssignToDelegateeCallBack);
        logger.trackTrace("onReAssignToDelegatee with parameters" + JSON.stringify({ email: email, name: name }));
    }

    private onReAssignToDelegateeCallBack = (status: boolean) => {
        if (status === true) {
            SiriusNotifier.Success("Delegated signer updated successfully!", "");
        }
        else {
            SiriusNotifier.Warning("Cannot update delegated signer", "")
        }
        this.setState({
            showAssignToSomeoneElseModal: false,
            finishEnabled: true,
            isAssignedToDelegatee: true,
            delegateeSendEnabled: true
        });
    }

    private onDeclineCallBack = (status: boolean) => {
        let param: any = this.props.match.params;
            if (status === true) {
                window.location.href = '/declined/' + param.clientId;
            }
            else {
                this.setState({ 
                    declineInProgress: false
                });
                SiriusNotifier.Error("Declining your signing process failed!", "")
            }
    }

    private onAssignToDelegateeCallBack = (status: boolean) => {

        const senderName = this.props.signerData.SignatureStatus.senderName;
        if (status === true) {
            SiriusNotifier.Success("You've changed the Signer. We've notified the " + senderName + " and the new Signer. You'll receive an email copy once everyone has signed.", "");
        }
        else {
            SiriusNotifier.Error("Assign to new signer failed!", "")
        }
        this.setState({
            showAssignToSomeoneElseModal: false,
            finishEnabled: true,
            isAssignedToDelegatee: true,
            delegateeSendEnabled: true
        });
    }

    private onDeclineCancel = () => {
        this.setState({ showDeclineModal: false });
    }

    private onAssignToSomeoneElseCancel = () => {
        this.setState({ showAssignToSomeoneElseModal: false });
    }

    private handleSigningCompleted = () => {
        this.setState({ finishEnabled: true });
    }

    private downloadInitialDocuments = () => {
        const param: any = this.props.match.params;
        const fileName = this.props.signerData.DocumentDetails[0].fileName.split('.');
        this.props.downloadAllDocument(param.clientId, fileName[0] + ".zip",
            this.props.signerData.SignatureStatus.isAllSigned, DownloadStep.Esign)
    }

    public render() {
        return (<div>
            <div id='delegateButtonContainer'>
                {this.props.signerData.SignatureStatus.recipientType == RecipientType.Signer && 
                    <ButtonComponent
                    type='button'
                    variant='outline-primary'
                    size='medium'
                    onClick={this.onDocumentAssignedToSomeoneElse}
                    children={<><span style={{margin:'0 5% 3% 0'}}><DelegateIcon/></span> <span>Delegate</span></>}
                    />
                }
            </div>
            <Main>
                <PdfView documents={this.props.signerData.DocumentDetails}
                    onSigningCompleted={this.handleSigningCompleted}
                    disableNextButton={this.handleDisableNextButton}
                    AssignToDelegateeSigner={this.props.signerData.AssignToDelegateeSigner}
                    downloadInitialDocuments={this.downloadInitialDocuments}
                    ref={(ref: PdfView) => this._pdfView = ref} />
            </Main>

            <Footer
                isLoading={this.props.loaderData.loading}
                onDocumentSignDeclined={this.handleDocumentSignDeclined}
                finishEnabled={this.state.finishEnabled}
                onDocumentAssignedToSomeoneElse={this.onDocumentAssignedToSomeoneElse}
                onDocumentSignFinish={this.handleFinish}
                AssignToDelegateeSigner={this.props.signerData.AssignToDelegateeSigner}
                onCancelDelegation={this.onDelegationCancelled}
                DelegationAssigned={this.props.signerData.SignatureStatus.delegateeInfo.EmailId}
                recipientType={this.props.signerData.SignatureStatus.recipientType} />

            <DeclineRemarks show={this.state.showDeclineModal}
                onSubmit={this.onDecline}
                onCancel={this.onDeclineCancel}
                AssignToDelegateeSigner={this.props.signerData.AssignToDelegateeSigner} 
                declineInProgress={this.state.declineInProgress}/>

            <AssignToDelegatee show={this.state.showAssignToSomeoneElseModal}
            onSubmit={this.onAssignToDelegatee}
            onReAssign={this.onReAssignToDelegatee}
            onCancel={this.onAssignToSomeoneElseCancel}
            AssignToDelegateeSigner={this.props.signerData.AssignToDelegateeSigner}
            DelegationAssigned={this.props.signerData.SignatureStatus.delegateeInfo.EmailId}
            isLoading={this.props.loaderData.loading}
            sendEnabled={this.state.delegateeSendEnabled}  />

            <LogoutPopover
                showDialog={this.state.showLogoutDialog}
                onCancel={this.onCancelLogout}
                onLogout={this.onLogout} />
        </div>);
    }
}
