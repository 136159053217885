import { ApplicationState } from "../../store/index";
import { actionCreators as OtpStore } from '../../store/Account/OtpStore';
import { actionCreators as PublicStore } from '../../store/Common/PublicStore';
import { EsignPageLayout } from "./EsignPageLayout";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { IRecipientSignatureDetails } from "../../../src/Core/Domain/ViewModels/ClientSignatureStatus";

function mapStateToProps(state: ApplicationState) {
    return {
        userData: state.userData,
        publicUserToken: state.publicUserToken,
        publicData: state.publicData
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string) => dispatch(PublicStore.requestPrimaryDetails(Id)),
        requestCompanyLogo: (Id: string ) => dispatch(PublicStore.requestCompanyLogo(Id)),
        getTokenForAnonymous: (Id: string, callback: (clientId: string) => void) => dispatch(PublicStore.getTokenForAnonymous(Id, callback)),
        requestOTP: (Id: string) => dispatch(OtpStore.requestOTP(Id)),
        validateOTP: (Id: string, otp: string, callback: any) => dispatch(OtpStore.validateOTP(Id, otp, callback)),
        refreshToken: (clientId: string, callback?: () => void) => dispatch(OtpStore.refreshToken(clientId, callback)),
        dispatchUserToken: (clientId: string) => dispatch(PublicStore.dispatchUserToken(clientId)),
        requestClientSignatureStatus: (clientGuid: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void) =>
            dispatch(OtpStore.requestClientSignatureStatus(clientGuid, callback)),
        getAllStates: (Id: string) => dispatch(PublicStore.getAllStates(Id)),
        updateLockState: (isLocked: boolean) => dispatch(PublicStore.updateLockState(isLocked))

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(EsignPageLayout));

 