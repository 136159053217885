import { IDataService } from "./Abstraction/IDataService";
import axios, { AxiosRequestConfig } from 'axios';
import history from './History';
import { TYPES } from '../../../Startup/types';
import { container } from '../../../Startup/inversify.config';
import { ILocalStore } from '../../../Core/Utilities/LocalStore';
import { SIGNER_BASE_URL } from "../../../utils/AppConstants";

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);
export const inMemoryToken: Map<string, string> = new Map();

class AxiosDataService implements IDataService {
    private _baseUri: string = SIGNER_BASE_URL;
    // private _pageSize: number=10;
    constructor(clientId?: string) {
        //if (clientId && localStore.get(clientId)) {
        //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStore.get(clientId);
        //}

        if (clientId && inMemoryToken.has(clientId)) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + inMemoryToken.get(clientId);
        }

        axios.interceptors.request.use(function (config: any) {
            return config
        }, function (error: any) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response: any) {
            if (response.status === 401) {
                //redirect to login url
                localStore.remove('loggedIn');
                history.push('/invalid');
            }
            return response;
        }, function (error: any) {
            //console.log('Error : ', error);
            if (error && error.config && error.config.__isRetryRequest !== undefined && !error.config.__isRetryRequest) {
                if (error.response.status == "403" || error.response.status == "500") {
                    localStore.remove('loggedIn');
                    history.push(
                        '/invalid',
                        {
                            statusCode: error.response.status,
                            statusText: error.response.statusText,
                            message: error.response.data
                        }
                    );
                }
                else {
                    error.response.data.map((message: any) => {
                        console.error(message.ErrorMessage);
                    })
                }
                error.config.__isRetryRequest = true;
            }
            return Promise.reject(error);
        });
    }

    set(baseUri: string, pageSize: number) {
        this._baseUri = SIGNER_BASE_URL;
        //this._pageSize = pageSize;
    }

    getPaged<T>(page: number, uriPart?: string | undefined): any {
        return axios.get<T>(this._baseUri + uriPart);
    }

    get<T>(uriPart?: string | undefined, data?: any): any {
        return axios.get<T>(this._baseUri + uriPart, { params: data, method: 'get',  withCredentials: true });
    }

    getWithConfig<T>(uriPart?: string | undefined, config?: any): any {
        return axios.get<T>(this._baseUri + uriPart, config);
    }

    post<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean): any {
        return axios.post<T>(this._baseUri + uriPart, data, 
            {
            ...config,
            withCredentials: true
        }
        );
    }

    postJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined): any {
        return axios.post<T>(this._baseUri + uriPart, data, 
            { 
                headers: { 'Content-Type': 'application/json;utf-8'},
                withCredentials: true  
            });
    }

    delete(id: number, uriPart?: string | undefined): any {
        return axios.delete(this._baseUri + uriPart);

    }

    deleteExtended(data: any, uriPart?: any): any {
        throw new Error("Method not implemented.");
    }
}
export function axiosFetch(clientId?: string): AxiosDataService { return new AxiosDataService(clientId); };

export function storeTokenInMemory(clientId: string, token: string) { inMemoryToken.set(clientId, token); };