import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IRecipientSignatureDetails } from 'src/Core/Domain/ViewModels/ClientSignatureStatus';
import * as SignerStore from '../../store/Signing/SignerStore';
import * as PublicStore from '../../store/Common/PublicStore';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import * as LoaderStore from '../../store/Common/LoaderStore';
import { logger } from '../../routes';
import { DocumentUnavailableIcon } from '../Svg/CustomSvgIcons';

type DeclinedProps =
    {
        signingStatus: IRecipientSignatureDetails,
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData
    }
    & typeof SignerStore.actionCreators
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>

export const Declined: React.FC<DeclinedProps> = (props) => {

    React.useEffect(() => {
        let param: any = props.match.params;
        if(props.signingStatus.documentId == 0) {
            props.requestSignatureInfo(param.clientId);
        }
        logger.trackPageView("Document declined Page");
    }, [])

    return (
        <LoadingOverlay
            active={props.loaderData.loading}
            spinner={<MetroSpinner size={60} color="#3bbaea" loading={props.loaderData.loading} />}>
            <div id="content-wrapper" className="col-xs-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 invalid-page">
                    <div className="unavailable-icon"><DocumentUnavailableIcon /></div>
                    <div className="greeting text-center" style={{ width: "100%" }}>
                        <span className="common-pages-main-header">Document Unavailable</span><br />
                        <br />
                        <span className="common-pages-sub-header">The document you are attempting to access has been declined for the following reason:</span><br />
                            {(props.signingStatus.remark != null && props.signingStatus.remark != '') &&
                                <span>
                                    < br />
                                    <p className="font16 text-left">{props.signingStatus.remark}</p>
                                </span>
                            }
                        <br />
                        <p className="font14"><span className="gray-text-color">For more information, please contact the sender.</span>
                            <br />
                            <span className="fontWeight700">
                                {props.signingStatus.senderName}
                                <br />
                                {props.signingStatus.senderEmailAddress}
                            </span>
                        </p>
                    </div>
                </div>

            </div>
        </LoadingOverlay>
    );
}