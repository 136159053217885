import { ApplicationState } from "../../store/index";
import { Expired } from "./Expired";
import { connect } from 'react-redux';
import { IRecipientSignatureDetails } from 'src/Core/Domain/ViewModels/ClientSignatureStatus';
import { actionCreators as SignerStore } from '../../store/Signing/SignerStore';
import { actionCreators as PublicStore } from '../../store/Common/PublicStore';
import { ISignFinishedResponse } from "src/Core/Domain/ViewModels/ISignFinishedResponse";


function mapStateToProps(state: ApplicationState) {
    return {
        signingStatus: state.signerData.SignatureStatus,
        publicData: state.publicData,
        loaderData: state.loaderData
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string) => dispatch(PublicStore.requestPrimaryDetails(Id)),
        requestSignatureInfo: (clientGuid: string, callBack?: () => void) =>
            dispatch(SignerStore.requestSignatureInfo(clientGuid, callBack)),
        requestDocumentControls: (clientGuid: string, isControlsDisabled: boolean, skipTracking?: boolean) =>
            dispatch(SignerStore.requestDocumentControls(clientGuid, isControlsDisabled, skipTracking)),
        declineSigning: (clientGuid: string, remarks: string, callback: (status: boolean) => void) => dispatch(SignerStore.declineSigning(clientGuid, remarks, callback)),
        sign: (signatureData: any, callback: (status: ISignFinishedResponse) => void) => dispatch(SignerStore.sign(signatureData, callback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Expired);

 