import { EsignStrings } from '../../../Core/Common/Strings';
import {
    CompanyControl, CustomTextControl, IControl, InitialControl, ISignatureData, ITextData, SignatureControl, NameAndTitleControl,
    DateControl, PrintNameControl, TitleControl, CheckBoxControl, ICheckBoxData
} from '../../../Core/Domain/Models/Controls';
import { IValidationModel } from '../../../Core/Domain/Models/Validation';
import { IDocument, IPage, Document } from '../../../Core/Domain/ViewModels/Document';

export interface IEsignValidationModel extends IValidationModel {
    document: IDocument;
    page: number;
    control?: IControl;
}


export class EsignValidationModel implements IEsignValidationModel {
    status: boolean;
    message: string;
    document: IDocument;
    page: number;


    constructor(status: boolean,
        message: string,
        document: IDocument,
        page: number, control?: IControl) {

        this.status = status;
        this.message = message;
        this.document = document;
        this.page = page;
    }


    public static create(status: boolean,
        message: string,
        document: IDocument,
        page: number, control?: IControl) {

        return new EsignValidationModel(status, message, document, page, control);
    }
}


export class EsignManager {

    public static validateEsignData(documents: IDocument[]): IEsignValidationModel {

        let _self = this;
        let validationModel: IEsignValidationModel = EsignValidationModel.create(true, "", Document.createNullObject(), 0);
        
        try {

            for (var i = 0; i <= documents.length - 1; i++) { // doument loop

                if (!validationModel.status) {
                    break;
                }

                const pages = documents[i].pages;

                for (var j = 0; j <= pages.length - 1; j++) { // document pages loop

                    if (!validationModel.status) {
                        break;
                    }

                    const controls = pages[j].controls;

                    for (var k = 0; k <= controls.length - 1; k++) { // page controls loop

                        const control = controls[k];

                        if (control.required) {

                            validationModel = _self.validate(control);

                            if (!validationModel.status) {

                                const document = documents[i];
                                const page = pages[j];

                                validationModel.control = control;
                                validationModel.page = page.page;
                                validationModel.document = document;

                                break;
                            }

                        }

                    }
                }
            }
        }
        catch (error) {

        }

        return validationModel;

    }


    private static validate(control: IControl): IEsignValidationModel {

        let validationModel: IEsignValidationModel = EsignValidationModel.create(true, "", Document.createNullObject(), 0);


        if (control instanceof SignatureControl) {

            const signatureData: ISignatureData = control.data as ISignatureData;

            if (signatureData == undefined || !signatureData.signature) {
                validationModel = EsignValidationModel.create(false, EsignStrings.SIGNATURE_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }
        }
        else if (control instanceof DateControl) {

            const textData: ITextData = control.data as ITextData;

            if (textData == undefined || !textData.text) {
                validationModel = EsignValidationModel.create(false, EsignStrings.DATE_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else if (control instanceof InitialControl) {

            const initialData: ISignatureData = control.data as ISignatureData;

            if (initialData == undefined || !initialData.signature) {
                validationModel = EsignValidationModel.create(false, EsignStrings.INITIAL_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else if (control instanceof NameAndTitleControl) {

            const textData: ITextData = control.data as ITextData;

            if (textData == undefined || !textData.text) {
                validationModel = EsignValidationModel.create(false, EsignStrings.NAME_AND_TITLE_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else if (control instanceof PrintNameControl) {

            const textData: ITextData = control.data as ITextData;

            if (textData == undefined || !textData.text) {
                validationModel = EsignValidationModel.create(false, EsignStrings.NAME_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else if (control instanceof TitleControl) {

            const textData: ITextData = control.data as ITextData;

            if (textData == undefined || !textData.text) {
                validationModel = EsignValidationModel.create(false, EsignStrings.TITLE_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else if (control instanceof CompanyControl) {

            const textData: ITextData = control.data as ITextData;

            if (textData == undefined || !textData.text) {
                validationModel = EsignValidationModel.create(false, EsignStrings.COMPANY_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }


        }
        else if (control instanceof CustomTextControl) {

            const textData: ITextData = control.data as ITextData;

            if (textData == undefined || !textData.text) {
                validationModel = EsignValidationModel.create(false, EsignStrings.CUSTOM_TEXTBOX_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else if (control instanceof CheckBoxControl) {

            const checkBoxData: ICheckBoxData = control.data as ICheckBoxData;

            if (checkBoxData == undefined || checkBoxData.checked == null ||
                (control.required && checkBoxData.checked != null && checkBoxData.checked == false)
            ) {
                validationModel = EsignValidationModel.create(false, EsignStrings.CHECKBOX_VALIDATION_ERROR_MESSAGE, Document.createNullObject(), 0);
            }

        }
        else {
            throw new Error("Method not implemented.");
        }

        return validationModel;

    }

}