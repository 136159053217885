import { Document, IDocument, IPage, Page } from "../../../../Core/Domain/ViewModels/Document";
import { ISignerControlDataModal, SignerControlDataModal } from "../../../../Core/Domain/ViewModels/SignerControlDataModal";
import {
    CompanyControl, CustomTextControl, DateControl, IControl, ICustomData, InitialControl, ISignatureData, ITextData, SignatureControl,
    NameAndTitleControl, TextData, NameControl, TitleControl, CheckBoxControl, ICheckBoxData, ITextBoxControl, PrintNameControl, RadioControl, RadioGroupData
} from "../../../Domain/Models/Controls";
import { ControlRole, IControlRole, ISignerInfo, SignerInfo } from "../../../Domain/Models/CustomData";
import { FileSignatureDataViewModel, ISignatureControlModel, ISignaturePageData, SignatureControlModel, SignatureControlRole, SignaturePageData } from '../../../Domain/ViewModels/DocumentViewModel';
import { Guid } from '../../../Utilities/Guid';


export interface IDocumentAdapter {
    convertToClientModel(documentModel: FileSignatureDataViewModel[]): IDocument[];
    convertToServerModel(document: IDocument[]): ISignerControlDataModal;
    convertToClientModelWithDisable(documentModel: FileSignatureDataViewModel[]): IDocument[];
}

const signatureTopAdjustment: number = 5;

export class DocumentAdapter implements IDocumentAdapter {

    public static create(): DocumentAdapter {
        return new DocumentAdapter();
    }

    public convertToClientModel(documentModel: FileSignatureDataViewModel[]): IDocument[] {

        let _self = this;
        let documents: IDocument[] = [];


        documentModel.forEach(function (document: FileSignatureDataViewModel) {

            let tmpDocument: IDocument = Document.create(document.fileId,
                document.fileName,
                document.fileLink,
                document.disabled,
                []);

            document.signatureData.forEach(function (pageItem: ISignaturePageData) {

                let tmpControls: IControl[] = [];

                pageItem.signatureControls.forEach(function (controlItem: ISignatureControlModel) {

                    tmpControls.push(_self.convertToClientControlModel(controlItem));

                });

                tmpDocument.pages.push(Page.create(pageItem.pageNo, tmpControls));

            });

            documents.push(tmpDocument);

        });

        return documents;

    }  
       
    public convertToServerModel(documents: IDocument[]): ISignerControlDataModal {

        let _self = this;
        let signerControlDataModal: ISignerControlDataModal = SignerControlDataModal.create("", "", [], "");
 
        let documentsModel: FileSignatureDataViewModel[] = [];

        documents.forEach(function (document: IDocument) {
            let tmpDocument: FileSignatureDataViewModel = FileSignatureDataViewModel.create(document.fileId, document.fileName, document.fileLink, document.disabled, []);
            
            document.pages.forEach(function (page: IPage) {
                
                let tmpControls: ISignatureControlModel[] = [];
                
                page.controls.forEach(function (control: IControl) {
                    tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal));
                    
                });
                
                tmpDocument.signatureData.push(SignaturePageData.create(page.page, tmpControls));
                
            });
            
            documentsModel.push(tmpDocument);
            
        });
        
        signerControlDataModal.fileData = documentsModel;
        return signerControlDataModal; 
    }  

    public convertToClientModelWithDisable(documentModel: FileSignatureDataViewModel[]): IDocument[] {

        let _self = this;
        let documents: IDocument[] = [];

        documentModel.forEach(function (document: FileSignatureDataViewModel) {

            let tmpDocument: IDocument = Document.create(document.fileId,
                document.fileName,
                document.fileLink,
                document.disabled,
                []);
                document.signatureData.forEach(function (pageItem: ISignaturePageData) {
                    
                    let tmpControls: IControl[] = [];
                    
                    pageItem.signatureControls.forEach(function (controlItem: ISignatureControlModel) {
                        
                        tmpControls.push(_self.convertToClientControlModelWithDisable(controlItem));
                        
                    });
                    
                    tmpDocument.pages.push(Page.create(pageItem.pageNo,tmpControls));
                    
                });
                
                documents.push(tmpDocument);
        });

        return documents;

    }


    private convertToServerControlModel(control: IControl, signerControlDataModal: ISignerControlDataModal): ISignatureControlModel {

        let signatureControlModel: ISignatureControlModel;

        const signerInfo: ICustomData | undefined = control.customData.find(x => (x instanceof SignerInfo) == true);
        const controlRole: ICustomData | undefined = control.customData.find(x => (x instanceof ControlRole) == true);

        const signer: string = (signerInfo as ISignerInfo).email;
        const role: SignatureControlRole = (controlRole as IControlRole).role;
        
        if (control instanceof SignatureControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                1,
                control.top + signatureTopAdjustment, // to fix small change in signature position bug - #41668
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { hasValueIfOptional: (control.data && (control.data as ISignatureData).signature.trim().length > 0) ? true : false }, // Will be used to check if the person signed when the control is optional
                []); 

            if (signerControlDataModal.signature == null ||
                signerControlDataModal.signature == undefined ||
                (signerControlDataModal.signature != null && signerControlDataModal.signature != undefined && signerControlDataModal.signature.trim() == "")) {
                if (control.data != undefined && control.data != null) {
                    signerControlDataModal.signature = (control.data as ISignatureData).signature;
                }
            }
        }
        else if (control instanceof InitialControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                2,
                control.top + signatureTopAdjustment,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { hasValueIfOptional: (control.data && (control.data as ISignatureData).signature.trim().length > 0) ? true : false },
                []);

            if (signerControlDataModal.signatureInitial == null ||
                signerControlDataModal.signatureInitial == undefined ||
                (signerControlDataModal.signatureInitial != null && signerControlDataModal.signatureInitial != undefined && signerControlDataModal.signatureInitial.trim() == "")) {
                if (control.data != undefined && control.data != null) {
                    signerControlDataModal.signatureInitial = (control.data as ISignatureData).signature;
                }
            }
        }
        else if (control instanceof DateControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                3,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);

        }
        else if (control instanceof NameAndTitleControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                4,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);

        }
        else if (control instanceof CompanyControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                5,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);

        }
        else if (control instanceof CustomTextControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                6,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);
        }
        else if (control instanceof NameControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                7,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);

        }
        else if (control instanceof TitleControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                8,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);

        }
        else if (control instanceof CheckBoxControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                9,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { checked: control.data != undefined && control.data != null ? (control.data as ICheckBoxData).checked : false },
                []);

        }
        
        else if (control instanceof PrintNameControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                10,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text },
                []);

        }
        else if (control instanceof RadioControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                11,
                control.top,
                control.left,
                control.width,
                control.height,
                role,
                control.tooltip,
                control.required,
                control.data != undefined && control.data != null ? control.data : "",
                control.items);

        }
        else {
            throw new Error("Method not implemented.");
        }
        
        return signatureControlModel;

    }
          

    private convertToClientControlModel(control: ISignatureControlModel): IControl
    {

        let model: IControl;
        let customData: ICustomData[] = [];

        customData.push(SignerInfo.create(control.signer));
        customData.push(ControlRole.create(control.role));

        switch (control.type) {

            case 1: // signature

                model = SignatureControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined);

                break;

            case 2: // Initial                

                model = InitialControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined);

                break;

            case 3: // Date

                model = DateControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()));

                break

            case 4: // Name and title

                model = NameAndTitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;


            case 5: // company

                model = CompanyControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 6: // Text

                model = CustomTextControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 7: // Name

                model = NameControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 8: // Title

                model = TitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);

                break;

            case 9: // CheckBox

                model = CheckBoxControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined);

                break;

            case 10: //PrintName

                model = PrintNameControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined);
                break;

            case 11: //Radio button

                model = RadioControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, control.items, RadioGroupData.create(control.items), undefined);
                break;

            default:

                throw new Error("Method not implemented.");

        }

        return model;

    }


    private getCurrentDate = (): string => {

        var today = new Date();
        var dd = today.getDate().toString();

        var mm = (today.getMonth() + 1).toString();
        var yyyy = today.getFullYear();
        if (Number(dd) < 10) {
            dd = '0' + dd;
        }

        if (Number(mm) < 10) {
            mm = '0' + mm;
        }

        return mm + '/' + dd + '/' + yyyy;
    }

    private convertToClientControlModelWithDisable(control: ISignatureControlModel): IControl {

        let model: IControl;
        let customData: ICustomData[] = [];

        customData.push(SignerInfo.create(control.signer));
        customData.push(ControlRole.create(control.role));

        if (control.required === undefined || control.required === null) {
            control.required = true;
        }

        switch (control.type) {

            case 1: // signature

                model = SignatureControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined, true);

                break;

            case 2: // Initial                

                model = InitialControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined, true);

                break;

            case 3: // Date

                model = DateControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()), true);

                break

            case 4: // Name and title

                model = NameAndTitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;


            case 5: // company

                model = CompanyControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 6: // Text

                model = CustomTextControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 7: // Name

                model = NameControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 8: // Title

                model = TitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);

                break;

            case 9: // CheckBox

                model = CheckBoxControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, undefined, true);

                break;

            case 10: //PrintName

                model = PrintNameControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, false, customData, undefined, true);
                break;

            case 11: // Radio button

                model = RadioControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
                    control.tooltip, control.required, customData, control.items, RadioGroupData.create(control.items), true);

                break;
            default:

                throw new Error("Method not implemented.");

        }

        return model;

    }
}