import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IResponseNotifier } from '../../Core/Domain/ViewModels/IResponseNotifier'
import { NotificationType } from '../../Core/Common/Enums';
import { RequestClientSignatureStatusAction, ReceiveClientSignatureStatusAction } from '../Signing/SignerStore';
import { IRecipientSignatureDetails } from '../../Core/Domain/ViewModels/ClientSignatureStatus'
import { HttpAction } from '../Common/LoaderStore'
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { axiosFetch, storeTokenInMemory } from '../../Core/Services/DataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { AuthenticationConstants } from '../../components/Constants';
import { AuthenticationType } from '../../Core/Domain/ViewModels/PrimaryDetails';

export interface ITokenData {
    token: string;
}
export interface ReceiveUserTokenAction {
    type: actionTypes.RECEIVE_USER_TOKEN;
    token: string;
}



type KnownAction =
    DispatchAction |
    HttpAction |
    NotificationAction;

type DispatchAction = ReceiveUserTokenAction | RequestClientSignatureStatusAction | ReceiveClientSignatureStatusAction;

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export const actionCreators = {
   
    requestAccessCode: (Id: string, callback?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        return axiosFetch().get<boolean>('api/authenticate/accessCode/' + Id)
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                const message = state.publicData.primaryDetails.authenticationInfo.AuthenticationType === AuthenticationType.AccessCodeEmail ?
                    AuthenticationConstants.AccessCodeSent : AuthenticationConstants.AccessCodeMobileSent;
                    if (callback){
                        callback(true);
                    }
                    dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: data ? message : AuthenticationConstants.RequestAccessCodeFailed,
                    statusType: data ? StatusType.Success : StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                if (callback){
                    callback(false);
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            });
    },

    validateParam: (Id: string, param: string, callback: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        return axiosFetch().post<IResponseNotifier>('api/authenticate/' + param + '/' + Id)
            .then(function (response: AxiosResponse<IResponseNotifier>) {
                const result: IResponseNotifier = response.data;
                if (result.Type === NotificationType.Success) {
                    dispatch({
                        type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data.Token
                    });
                    localStore.set('loggedIn', true);
                    storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: result.Message,
                        statusType: StatusType.Error
                    });
                }
                callback && callback(result);
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            });
    },

    refreshToken: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        if (clientId) {
            return axiosFetch().get<IResponseNotifier>('api/authenticate/refresh/' + clientId)
                .then(function (response: AxiosResponse<IResponseNotifier>) {
                    const result: IResponseNotifier = response.data;
                    if (result.Type === NotificationType.Success) {
                        storeTokenInMemory(clientId, result.Data);
                        dispatch({
                            type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data
                        });
                        callback && callback();
                    }
                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION, statusMessage: result.Message,
                            statusType: StatusType.Error
                        });
                    }
                    dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                        statusType: StatusType.Error
                    });
                    dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                });
        }
        else {
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: "undefined"
            });
            dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
        }
    },

    requestClientSignatureStatus: (clientGuid: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.REQUEST_CLIENT_SIGNATURE_STATUS, clientGuid: clientGuid });
        return axiosFetch(clientGuid).get<IRecipientSignatureDetails>('api/Signing/GetRecipientSignatureStatus/')
            .then(function (response: AxiosResponse<IRecipientSignatureDetails>) {
                const result: IRecipientSignatureDetails = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS, SignatureStatus: result
                });
                callback(result, clientGuid, false);
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
            });
    },
}

const unloadedState: ITokenData = {
    token: ""
} as ITokenData;

export const tokenReducer: Reducer<ITokenData> = (state: ITokenData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_TOKEN:
            return {
                token: action.token
            } as ITokenData;
        default:
            return state || unloadedState;
    }
};