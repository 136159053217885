import * as React from 'react';
import { FC } from 'react';
import { ButtonComponent } from 'cp-common-ui-components';
import { Modal, Button } from 'react-bootstrap';

interface ILogoutPopoverProps {
    showDialog: boolean;
    onCancel: () => void;
    onLogout: (e: any) => void;
}

export const LogoutPopover:FC<ILogoutPopoverProps> = (props) => {
            return <Modal className='logout-popover' show={props.showDialog} onHide={props.onCancel}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Log Out
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span>Are you sure you want to log out ?</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <ButtonComponent
                    size="small"
                    variant="outline-tertiary"
                    onClick={props.onCancel}
                    >
                Cancel
            </ButtonComponent>
            <ButtonComponent
                    size="small"
                    variant="primary"
                    onClick={props.onLogout}
                    >
                Log Out
            </ButtonComponent>
                {/* <Button
                    className="btn-white"
                     >
                    <i className='glyphicon glyphicon-remove'></i>Cancel
                </Button>
                <Button
                     >
                    <i className='fa fa-sign-out-alt'></i>Sign Out
                </Button> */}
            </Modal.Footer>
        </Modal>
}