import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ButtonComponent } from 'cp-common-ui-components';

interface DeclineRemarksProps {
	onSubmit: (remarks: string) => void;
	onCancel: () => void;
    show: boolean;
    AssignToDelegateeSigner: boolean;
	declineInProgress: boolean;
}

export const DeclineRemarks: React.FC<DeclineRemarksProps> = (props) => {

	const [remarks, setRemarks] = React.useState<string>('');

	const onSubmit = () => {
		props.onSubmit(remarks);
	}

	return (
		<Modal show={props.show} onHide={props.onCancel} centered={true} className="decline-modal">
			<Modal.Header closeButton>
				<Modal.Title>
					Decline Signing Confirmation
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="col-md-12">
					<div className="form-group modal-description">
						<span>If you select "Decline", this document will be voided and inaccessible to other signers.</span>
					</div>
					<div className="form-group">
						<label htmlFor='remarks' style={{margin:'0px'}} className='fontWeight600 font16'>Please enter the reason for declining below:</label>
						<textarea rows={6}
							value={remarks}
							onChange={(e) => { setRemarks(e.target.value); }}
							className="form-control reasonForTextArea"
							id="remarks"
							placeholder={"Enter Reason"}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onCancel}
					variant={"light"}
					className="btn btn-cancel"
					data-test-auto="c2f7dfe0-7ca8-4623-a8fb-2f4ac18e1a7b">
					Cancel
				</Button>

				<Button onClick={onSubmit}
					variant={"Danger"}
					className="btn btn-danger"
					data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0"
					disabled={props.declineInProgress}>
					Decline
				</Button>
			</Modal.Footer>
		</Modal>
	);
}