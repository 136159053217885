export class DisplayDownloadFile {

	public showFile = (blob: Blob, fileName: string) => {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		var newBlob = new Blob([blob], { type: blob.type })

		
		//For IOS Chrome
		if(window.navigator && window.navigator.userAgent.match('CriOS')){
			var reader = new FileReader();
			reader.onload = () => window.location.href = reader.result as string;
 			reader.readAsDataURL(newBlob);
			 return;
		}

		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob, fileName);
			return;
		}

		// For other browsers: 
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement('a');
		link.href = data;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		setTimeout(function () {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(data);
			document.body.removeChild(link);
		}, 100);
	}
}