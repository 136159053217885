import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';

export enum StatusType {
    None,
    Error,
    Success,
    Warning,
}

export interface NotificationState {
    message: string;
    type: StatusType;
}

export interface NotificationAction {
    type: actionTypes.NOTIFICATION;
    statusMessage: string;
    statusType: StatusType;
}

type KnownAction = NotificationAction;

const unloadedState: NotificationState = {
    message: "",
    type: StatusType.None
} as NotificationState;

export const reducer: Reducer<NotificationState> = (state: NotificationState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.NOTIFICATION:
            return {
                message: action.statusMessage,
                type: action.statusType
            } as NotificationState;

        default:
            return state || unloadedState;
    }
}
