import { ClientTypes } from '../../Common/Enums';

export interface IRecipientSignatureDetails {
    documentId: number,
    documentStatus: DocumentStatus,
    isExpired: boolean,
    isAllSigned: boolean,
    recipientId: string,
    recipientType: RecipientType,
    declinedRemarks: string,
    cancelledRemarks: string,
    senderFirstName: string,
    senderLastName: string,
    senderEmailAddress: string,
    senderName: string,
    companyName: string,
    delegateeInfo: IDelegateeInfo,
    stampingStatus: StampingStatus,
    remark: string,
}

export interface IDelegateeInfo {
    DelegateeId: string,
    Name: string,
    EmailId: string,
}

export enum DocumentStatus {
    None = 0,
    Processing = 1,
    OutForDelivery = 2,
    ESigned = 3,
    Declined = 4,
    Cancelled = 5,
    PartiallySigned = 6,
    SignatureStampingFailed = 7,
    DeliveryFailed = 8,
    Deleted = 9,
    Draft = 10
}

export enum RecipientType {
    None = 0,
    Signer = 1,
    Cc = 2,
    Delegatte = 3
}

export enum StampingStatus {
    None = 0,
    Processing = 1,
    Completed = 2,
    Failed = 3
}
export enum SignatureDocumentStatus {
    Active = 1,
    Archived = 2,
    Recycled = 3,
    Deleted = 4,

}

export const initialClientSignatureStatus: Readonly<IRecipientSignatureDetails> = {
    documentId: 0,
    documentStatus: DocumentStatus.None,
    isExpired: false,
    isAllSigned: false,
    recipientId: "",
    recipientType: RecipientType.None,
    declinedRemarks: "",
    cancelledRemarks: "",
    senderFirstName: "",
    senderLastName: "",
    senderEmailAddress: "",
    senderName: "",
    companyName: "",
    delegateeInfo: {
        DelegateeId: "",
        Name: "",
        EmailId: ""
    },
    stampingStatus: StampingStatus.None,
    remark: "",
}

