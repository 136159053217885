import * as React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Esign from './components/Esign/ESignContainer';
import { ProtectedRoute } from './ProtectedRoutes';
import { InvalidLink } from './components/CommonPages/InvalidLink';
import { StampingNotComplete } from './components/CommonPages/StampingNotComplete';
import Cancelled from './components/CommonPages/CancelledContainer';
import Declined from './components/CommonPages/DeclinedContainer';
import Expired from './components/CommonPages/ExpiredContainer';
import Download from './components/CommonPages/DownloadContainer';
import SigningComplete from './components/CommonPages/SigningCompleteContainer';
import SigningSuccess from './components/CommonPages/SigningSuccessContainer';
import LogoutSuccess from './components/Account/Logout/LogoutSuccessContainer';
import { DefaultLayout } from './components/Layout/Layout';
import RouteSelector from './components/Account/RouteSelectorContainer';
import { LoggerFactory } from './Logger/LoggerFactory';
export const logger = new LoggerFactory().getTelemetryLogger();

export const routes = (
    <Switch>
        <RouteWrapper exact path="/invalid" component={InvalidLink} />
        <ProtectedRouteWrapper exact path="/cancelled/:clientId" component={Cancelled} layout={DefaultLayout} />
        <ProtectedRouteWrapper exact path="/declined/:clientId" component={Declined} layout={DefaultLayout} />
        <ProtectedRouteWrapper exact path="/expired/:clientId" component={Expired} layout={DefaultLayout} />
        <ProtectedRouteWrapper exact path="/signingcomplete/:clientId" component={SigningComplete} layout={DefaultLayout} />
        <ProtectedRouteWrapper exact path="/download/:clientId" component={Download} layout={DefaultLayout} />
        <ProtectedRouteWrapper exact path="/signingsuccess/:clientId" component={SigningSuccess} layout={DefaultLayout} />
        <RouteWrapper exact path="/logout/:clientId" component={LogoutSuccess} layout={DefaultLayout} />
        <ProtectedRouteWrapper path="/esign/:clientId" component={Esign} layout={DefaultLayout} />
        <RouteWrapper path="/login/:Id" component={RouteSelector} layout={DefaultLayout} />
        <RouteWrapper path="/signingprogress" component={StampingNotComplete} layout={DefaultLayout} />
        <RouteWrapper path="*" component={InvalidLink} />
    </Switch>
);

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    return (
        <Route {...rest} render={(props) =>
            Layout ?
                <Layout {...props}>
                    <Component {...props} />
                </Layout> :
                <Component {...props} />
        } />
    );
}

function ProtectedRouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    return (
        <ProtectedRoute {...rest} component={Component} layout={Layout} />
    );
}
