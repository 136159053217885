import React, { useEffect } from 'react'
import "babel-polyfill";
import "isomorphic-fetch";
import * as ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import * as RoutesModule from './routes';
import { Provider } from 'react-redux';
import history from './Core/Services/DataAccess/History';
import configureStore from './configureStore';
import { ILocalStore } from './Core/Utilities/LocalStore';
import { container } from './Startup/inversify.config';
import { ApplicationState } from './store';
import { TYPES } from './Startup/types';

const App = () => {
    const initialState = (window as any).initialReduxState as ApplicationState;
    const store = configureStore(history, initialState);
    let routes = RoutesModule.routes;
   
   return( <Provider store={store}>
                <ConnectedRouter children={routes} history={history}/>
            </Provider>);
}

export default App;