import * as React from 'react';
import { ClientTypes } from '../../../src/Core/Common/Enums';
import { ButtonComponent } from 'cp-common-ui-components';
import { isStringNullOrEmpty } from '../Helper/HelperFunctions';
import { RecipientType } from 'src/Core/Domain/ViewModels/ClientSignatureStatus';

interface FooterProps {
    onDocumentSignFinish(): void;
    onDocumentSignDeclined(): void;
    onDocumentAssignedToSomeoneElse(): void;
    finishEnabled: boolean;
    AssignToDelegateeSigner: boolean;
    DelegationAssigned?: string;
    onCancelDelegation(): void;
    isLoading: boolean;
    recipientType: RecipientType;
}

export const Footer:React.FC<FooterProps>= (props) =>{
    return (<footer className="footerContainer">
                <div>
                {props.recipientType == RecipientType.Signer && (props.DelegationAssigned || props.AssignToDelegateeSigner) ?
                    <ButtonComponent
                        size="medium"
                        variant='danger'
                        disabled={props.isLoading}
                        onClick={props.onCancelDelegation}
                        id="btnCancelDecline" buttonClassName='btn-decline '>
                        Cancel Delegation
                    </ButtonComponent>
                        :
                    <ButtonComponent
                    size="medium"
                    variant='danger'
                    disabled={props.isLoading}
                    onClick={props.onDocumentSignDeclined}
                    id="btnDecline" children='Decline' buttonClassName='btn-decline'/>
                }

                    <ButtonComponent
                    size="small"
                    type="button"
                    variant="success"
                    id="btnContinue"
                    buttonClassName='btn-finish'
                    children="Done"
                    onClick={props.onDocumentSignFinish} disabled={!props.finishEnabled}
                    />
            
                    {/* {props.AssignToDelegateeSigner == true || (props.DelegationAssigned != null && props.LoggedInClientType != ClientTypes.Delegatee) ?
                        <a className="rd-nav-link btn-delegate">
                            <button
                                type="submit"
                                disabled={props.isLoading}
                                onClick={props.onDocumentAssignedToSomeoneElse}
                                className="btn btn-danger btn-danger-extended btn-login btn-assign-to-delegatee-color"
                                data-test-auto="18DE4980-937F-4266-812F-C5553A9C366F"
                                id="btnAssignToDelegatee">
                                Update Delegation
                            </button>
                        </a>
                        :
                        (props.LoggedInClientType != ClientTypes.Delegatee ?
                            
                            <a className="rd-nav-link btn-delegate">
                                <button
                                    type="submit"
                                    disabled={props.isLoading}
                                    onClick={props.onDocumentAssignedToSomeoneElse}
                                    className="btn btn-danger btn-danger-extended btn-login btn-assign-to-delegatee-color"
                                    data-test-auto="18DE4980-937F-4266-812F-C5553A9C366F"
                                    id="btnAssignToDelegatee">
                                    Delegate to someone else
                                </button>
                            </a> : "")
                    } */}
                </div>
   
        </footer>);
};