import React from 'react';
import ReactDOM from 'react-dom';
import 'jquery'
import 'bootstrap';
import './assets/scripts/polyfill.min.js'
import './assets/scripts/tinymce.min.js'
import App from "./App";
import reportWebVitals from './reportWebVitals';

import '../node_modules/awesome-pdf-viewer/dist/index.css'
import '../node_modules/awesome-pdf-viewer/dist/styles/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import '../node_modules/react-toastify/dist/ReactToastify.css'
import './assets/css/site.css'
import './assets/css/custom.css'
import './assets/images/favicon.ico'

ReactDOM.render(<App />, document.getElementById("app"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


if (module.hot) {
    module.hot.accept();
  }