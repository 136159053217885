import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IResponseNotifier } from '../../Core/Domain/ViewModels/IResponseNotifier'
import { NotificationType } from '../../Core/Common/Enums';
import { RequestClientSignatureStatusAction, ReceiveClientSignatureStatusAction } from '../Signing/SignerStore';
import { IRecipientSignatureDetails } from '../../Core/Domain/ViewModels/ClientSignatureStatus'
import { HttpAction } from '../Common/LoaderStore'
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { axiosFetch, storeTokenInMemory } from '../../Core/Services/DataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

export interface ITokenData {
    token: string;
}

export interface ReceiveUserTokenAction {
    type: actionTypes.RECEIVE_USER_TOKEN;
    token: string;
}

type KnownAction =
    DispatchAction |
    HttpAction |
    NotificationAction;

type DispatchAction = ReceiveUserTokenAction | RequestClientSignatureStatusAction | ReceiveClientSignatureStatusAction

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export const actionCreators = {
    requestOTP: (Id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        return axiosFetch().get<boolean>('api/Otp/RequestAccessCodeAsync' + Id)
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: data ? "One-time access code has been sent to your registered email address" :
                        "Failed to send one-time access code. Please check with the contact person", statusType: data ? StatusType.Success : StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`requestOTP failed for the request having Id ${Id} with error ${error.message}`)
            });
    },

    validateOTP: (Id: string, otp: string, callback: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        return axiosFetch().post<IResponseNotifier>('api/Otp/' + otp + '/' + Id)
            .then(function (response: AxiosResponse<IResponseNotifier>) {
                const result: IResponseNotifier = response.data;
                if (result.Type === NotificationType.Success) {
                    dispatch({
                        type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data.Token
                    });
                    localStore.set('loggedIn', true);
                    storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
                    callback(result.Data.ClientGuid);
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: result.Message,
                        statusType: StatusType.Error
                    });
                }
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`validateOTP failed for the request having parameters ${JSON.stringify({ Id: Id, otp: otp})} with error ${error.message}`)
            });
    },

    refreshToken: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        if (clientId) {
            return axiosFetch().get<IResponseNotifier>('api/Otp/refresh/' + clientId)
                .then(function (response: AxiosResponse<IResponseNotifier>) {
                    const result: IResponseNotifier = response.data;
                    if (result.Type === NotificationType.Success) {
                        storeTokenInMemory(clientId, result.Data);
                        dispatch({
                            type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data
                        });
                        if (callback) {
                            callback();
                        }
                    }
                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION, statusMessage: result.Message,
                            statusType: StatusType.Error
                        });
                    }
                    dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                        statusType: StatusType.Error
                    });
                    dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                    logger.trackError(`refreshToken failed for the request having clientId ${clientId} with error ${error.message}`)
                });
        }
        else {
            // we are tricking the OtpContainer for state change so that it re-renders
            // OtpComponent. We need to rethink and fix it in proper way in future.
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: "undefined"
            });
            dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
        }
    },

    requestClientSignatureStatus: (clientGuid: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.REQUEST_CLIENT_SIGNATURE_STATUS, clientGuid: clientGuid });
        return axiosFetch(clientGuid).get<IRecipientSignatureDetails>('api/Signing/GetRecipientSignatureStatus/')
            .then(function (response: AxiosResponse<IRecipientSignatureDetails>) {
                const result: IRecipientSignatureDetails = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS, SignatureStatus: result
                });
                callback(result, clientGuid, false);
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });

                logger.trackError(`requestClientSignatureStatus failed for the request having clientGuid ${clientGuid} with error ${error.message}`)
            });
    },

};

const unloadedState: ITokenData = {
    token: ""
} as ITokenData;

export const tokenReducer: Reducer<ITokenData> = (state: ITokenData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_TOKEN:
            return {
                token: action.token
            } as ITokenData;
        default:
            return state || unloadedState;
    }
};
