import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IRecipientSignatureDetails } from 'src/Core/Domain/ViewModels/ClientSignatureStatus';
import * as SignerStore from '../../store/Signing/SignerStore';
import * as PublicStore from '../../store/Common/PublicStore';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import * as LoaderStore from '../../store/Common/LoaderStore';
import { logger } from '../../routes';
import { BigCheckCircleIcon } from '../Svg/CustomSvgIcons';

type SigingCompleteProps =
    {
        signingStatus: IRecipientSignatureDetails,
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData
    }
    & typeof SignerStore.actionCreators
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>

export class SigningComplete extends React.Component<SigingCompleteProps, {}> {
    componentDidMount() {
        let param: any = this.props.match.params;
        if (this.props.signingStatus.documentId == 0) {
            this.props.requestSignatureInfo(param.clientId);
        }
        logger.trackPageView("Signing Completed Page");
    }
    public render() {
        return <LoadingOverlay
            active={this.props.loaderData.loading}
            spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.loaderData.loading} />}>
            <div id="content-wrapper"  className="signingCompletedContainer">
                <div className='signingCompletedBox'>
                    <BigCheckCircleIcon/>
                    <div  className='font20 fontWeight700' style={{marginTop:'3%'}}>Signing Completed</div>
                    <div className='font16 fontWeight400' style={{marginTop:'5%'}}>You have successfully signed the <br/> document(s). A copy will be emailed to <br/> you once everyone has signed.</div>
                </div>
            </div>
        </LoadingOverlay>
    }
}
