import * as React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { SiriusNotifier } from '../Common/Notification/SiriusNotifier';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import { isStringNullOrEmpty } from '../Helper/HelperFunctions';

let initialState = {} as IAssignToDelegateeState;

interface IAssignToDelegateeProps {
    onSubmit: (email: string, name: string, reason: string) => void;
    onReAssign: (email: string, name: string, reason: string) => void;
    onCancel: () => void;
    show: boolean;
    AssignToDelegateeSigner: boolean;
    DelegationAssigned?: string;
    isLoading: boolean;
    sendEnabled: boolean;
}

interface IAssignToDelegateeState {
    email: string;
    fullName: string;
    char_Left: number;
    reason: string;
    hideAlert: boolean;
}

const MAX_CHAR = 200;

export class AssignToDelegatee extends React.Component<IAssignToDelegateeProps, IAssignToDelegateeState> {
    constructor(props: IAssignToDelegateeProps, states: IAssignToDelegateeState) {
        super(props, states);
        this.state = {
            email: "",
            fullName: "",
            char_Left: MAX_CHAR,
            reason: "",
            hideAlert: true,
        }
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeReason = this.onChangeReason.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentWillMount() {
        initialState = { ...this.state };
    }

    private onChangeEmail(event: any) {
        this.setState({ email: event.target.value })
    }

    private onChangeName(event: any) {
        this.setState({ fullName: event.target.value })
    }

    private onChangeReason(event: any) {
        let input = event.target.value;
        if (MAX_CHAR - input.length >= 0) {
            this.setState({
                reason: event.target.value,
                char_Left: MAX_CHAR - input.length,
                hideAlert: true,
            });
        }
        else {
            this.setState({ hideAlert: false });
        }
    }

    private validateDetails = () => {
        var result = true;
        if (this.state.fullName.trim() == '') {
            SiriusNotifier.Error("Please enter signer name.", "");
            return result = false;
        }
        else if (this.state.email.trim() == "") {
            SiriusNotifier.Error("Please enter email address.", "");
            return result = false;
        }
        else if (!this.isValidEmailAddress(this.state.email.trim()) || this.state.email.trim() == "") {
            SiriusNotifier.Error("Please enter valid  email address.", "");
            return result = false;
        }
        return result;
    }

    private onSubmit = () => {
        var isValidate = this.validateDetails();
        if (isValidate) {
            this.props.onSubmit(this.state.email, this.state.fullName, this.state.reason);
        }
    }

    private onReAssign = () => {
        var isValidate = this.validateDetails();
        if (isValidate) {
            this.props.onReAssign(this.state.email, this.state.fullName, this.state.reason);
        }
    }

    private onCancel() {
        this.props.onCancel();
        this.setState({
            ...initialState,
        });
    }

    private isValidEmailAddress(emailAddress: any) {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    };

    public render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onCancel} id="delegatee" centered={true}>
                <LoadingOverlay className="delegatee-overlay"
                    active={this.props.isLoading}
                    spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.isLoading} />}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.AssignToDelegateeSigner == true || !isStringNullOrEmpty(this.props.DelegationAssigned) ?
                                <div>
                                    <div> Redelegate Signing</div>
                                    <div className="sub-header">
                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                        <span style={{ marginLeft: '10px' }}>Upon Re-Delegating, the initial email link sent to the previous signer will be invalid.</span>
                                    </div>
                                </div>
                                : <div>Delegate Signing</div>}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '20px' }}>
                        <div className="modal-description">
                            Once "Delegatee" is selected, an email with an access link will be sent to the delegatee you chose. 
                            Your firm will also receive a notification informing them the document has been delegated for review or signing.
                        </div>
                        <div className="row" style={{ fontSize: '14px' }}>
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="label"><label htmlFor='delegateeName'>Name</label><span className="glyphicon glyphicon-asterisk delegatee-compulsory-field"></span></div>
                                <div className="delegatee-textbox">
                                    <input type="text"
                                        className="form-control delegatee-input-field"
                                        id="delegateeName"
                                        value={this.state.fullName}
                                        onChange={this.onChangeName}
                                        data-test-auto="3F21CD09-C6AF-46F0-81A4-F42934F36903"
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="label"><label htmlFor='delegateeEmail'>Email</label><span className="glyphicon glyphicon-asterisk delegatee-compulsory-field"></span></div>
                                <div className="delegatee-textbox">
                                    <input type="text"
                                        className="form-control delegatee-input-field"
                                        value={this.state.email}
                                        id="delegateeEmail"
                                        onChange={this.onChangeEmail}
                                        data-test-auto="3F21CD09-C6AF-46F0-81A4-F42934F36903"
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="label"><label htmlFor='delegateeReason'>Reason for changing signing responsibility:</label></div>
                                <textarea value={this.state.reason}
                                    onChange={this.onChangeReason}
                                    id="delegateeReason"
                                    className="form-control reasonForTextArea"
                                    placeholder="Enter reason"
                                />
                                <div className="label"><span> {this.state.char_Left + " characters remaining"}</span></div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.onCancel}
                            variant={"light"}
                            className="btn btn-cancel"
                            data-test-auto="A07EAD32-1B78-44D2-9F41-001D3FCFFF8F">
                            Cancel
                        </Button>
                        {this.props.AssignToDelegateeSigner == true || !isStringNullOrEmpty(this.props.DelegationAssigned) ?
                            <Button
                                onClick={this.onReAssign}
                                variant={"primary"}
                                disabled={!this.props.sendEnabled}
                                className="btn btn-primary"
                                data-test-auto="36433FF7-E7F8-4374-B7B4-19C2D22AB829">
                                Delegate
                          </Button>
                            :
                            <Button
                                onClick={this.onSubmit}
                                variant={"primary"}
                                disabled={!this.props.sendEnabled}
                                className="btn btn-primary"
                                data-test-auto="36433FF7-E7F8-4374-B7B4-19C2D22AB829">
                                Delegate
                          </Button>
                        }
                    </Modal.Footer>
                </LoadingOverlay>
                </Modal>
        )
    }
}
