import { Container } from "inversify";
import "reflect-metadata";
import { UtilityFactory } from "../Core/Utilities/Factory/UtilityFactory";
import { ILocalStore } from "../Core/Utilities/LocalStore";
import { ITextUtilities } from "../Core/Utilities/TextUtilities";
import { IWindowUtilities } from "../Core/Utilities/WindowUtilities";
import { IHtmlUtilities } from "../Core/Utilities/HtmlUtilities";
import { IDialogBox } from '../Core/Utilities/UI/DialogBox';
import { RegexUtilities, IRegexUtilities } from "../Core/Utilities/RegexUtilities";
import { Utilities, IUtilities } from "../Core/Utilities/Utilities";
import { TYPES } from "./types";

const container = new Container();

container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().createLocalStore());
container.bind<ITextUtilities>(TYPES.ITextUtilities).toConstantValue(new UtilityFactory().createTextUtilityService());
container.bind<IWindowUtilities>(TYPES.IWindowUtilities).toConstantValue(new UtilityFactory().createWindowUtilityService());
container.bind<IHtmlUtilities>(TYPES.IHtmlUtilities).toConstantValue(new UtilityFactory().createHtmlUtilities());
container.bind<IDialogBox>(TYPES.IDialogBox).toConstantValue(new UtilityFactory().createDialogBox());
container.bind<IRegexUtilities>(TYPES.IRegexUtilities).to(RegexUtilities);
container.bind<IUtilities>(TYPES.IUtilities).to(Utilities);

export { container };
