export enum actionTypes {
    COMPLETE_RESPONSE,
    DECLINE_SIGNING,
    INITIATE_REQUEST,
    NOTIFICATION,
    RECEIVE_CLIENT_SIGNATURE_STATUS,
    REQUEST_PRIMARY_INFO,
    RECEIVE_PRIMARY_INFO,
    RECEIVE_COUNTRY_STATES,
    RECEIVE_DOCUMENT_CONTROLS,
    RECEIVE_DOCUMENTS,
    RECEIVE_PREPARER_MESSAGE,
    RECEIVE_USER_TOKEN,
    REQUEST_CLIENT_SIGNATURE_STATUS,
    REQUEST_DOCUMENT_CONTROLS,
	REQUEST_DOCUMENTS,
    VERIFY_USER_AUTHENTICATED,
    REQUEST_PUBLIC_USER_ACCESSTOKEN,
    RECEIVE_PUBLIC_USER_ACCESSTOKEN,
    RECEIVE_ASSIGN_TO_DELEGATEE_STATUS,
    DECLINE_DELEGATION,
    RECEIVE_COMPANY_LOGO,

    UPDATE_LOCK_STATE
}