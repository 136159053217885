import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IRecipientSignatureDetails } from 'src/Core/Domain/ViewModels/ClientSignatureStatus';
import { IFileControl } from '../../Core/Domain/ViewModels/DocumentInfo';
import * as SignerStore from '../../store/Signing/SignerStore';
import * as DownloadStore from '../../store/Download/DownloadStore';
import * as PublicStore from '../../store/Common/PublicStore';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import * as LoaderStore from '../../store/Common/LoaderStore';
import { DownloadStatus } from '../../Core/Common/Common';
import { DownloadStep } from '../../Core/Common/Enums';
import { logger } from '../../routes';
import { ButtonComponent } from 'cp-common-ui-components';
import { DownloadSvg, PaperClipSvg } from '../Svg/CustomSvgIcons';

let moment = require('moment');

type DownloadProps =
    {
        signingStatus: IRecipientSignatureDetails,
        documents: IFileControl[],
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData
    }
    & typeof SignerStore.actionCreators
    & typeof DownloadStore.actionCreators
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>
    
    export const Download:React.FC<DownloadProps> = (props) => {
    
        React.useEffect(() => {
            let param: any = props.match.params;
            if (props.signingStatus.documentId == 0) {
                props.requestSignatureInfo(param.clientId);
            }
            props.requestDocuments(param.clientId);
            logger.trackPageView("Document download Page");
        }, []);
    
        const downloadDocument = (documentGuid: string, fileName: string) => {
            let param: any = props.match.params;
            props.downloadDocument(param.clientId, documentGuid, fileName);
    
            logger.trackTrace("Download Document with documentGuid" + documentGuid);
        }
    
        const downloadAuditFile = (fileName: string) => {
            let param: any = props.match.params;
            props.downloadAuditFile(param.clientId, fileName);
            logger.trackTrace("Download Audit File with fileName" + fileName);
        }
    
        const downloadAllDocumet = () => {
            let param: any = props.match.params;
            let fileName: string = "Signed_Files_" + Number(new Date()) + ".zip";
            props.downloadAllDocument(param.clientId, fileName, props.signingStatus.isAllSigned, DownloadStep.Download);
    
            logger.trackTrace("Download All document with parameters" + JSON.stringify(param));
        }
    
        return <LoadingOverlay active={props.loaderData.loading}
                spinner={<MetroSpinner size={60} color="#3bbaea" loading={props.loaderData.loading} />}>
                <div className='downloadAllButtonContainer'>
                        <ButtonComponent
                            type='button'
                            variant='outline-primary'
                            size='medium'
                            data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1"
                            title={"Download All"}
                            onClick={downloadAllDocumet}
                            children={<><span style={{margin:'0 5% 3% 0'}}><DownloadSvg/></span> <span>Download All</span></>}
                            />
                </div>
                <div className='downloadContainer'>
                    <div className='downloadBox'>
                        <div className='fontWeight700 font18'>Download Signed Documents</div>
                            <div data-test-auto="EDE084EA-60DA-4F72-BF01-17A8F47F744B">
                                {
                                    <>
                                        <div className='downloadListHeader'>
                                                <div className="col-sm-6"> File Name </div>
                                                <div className="col-sm-3"> Expires On </div>
                                                <div className="col-sm-3"> Actions </div>
                                        </div>
                                        {props.documents.map((value, i) => {
                                                let gmtDateTime = moment.utc(value.expiresOn);
                                                let expiresOn = gmtDateTime.local().format('MM/DD/YYYY');
                                                let disable = new Date() > gmtDateTime.local();
                                                let expiryText = expiresOn;
                                                let fileName = value.fileName.indexOf(".") > -1 ?
                                                    value.fileName.substring(0, value.fileName.lastIndexOf(".")) : value.fileName;
                                                fileName = value.documentName + " " + fileName + " " + value.documentStatusName + ".pdf";
                                                let linkCSS = disable === true ? 'gray-text-color' : 'navy-blue-color';
                                                return <div className='downloadFileList fontWeight400' key={value.fileId}>
                                                            <div  title={fileName} className="col-sm-6 font16">
                                                                <span><PaperClipSvg/></span>
                                                                {fileName}
                                                            </div>
                                                            <div className="col-sm-3 font14">
                                                                <span >{expiryText}</span>
                                                            </div>
                                                            <div className='col-sm-3 font16'>
                                                            <ButtonComponent variant={"link"} style={{cursor: disable ? 'not-allowed': 'pointer'}}
                                                                 buttonClassName={linkCSS + ' download-button'} disabled={disable} onClick={() => downloadDocument(value.fileId, fileName)}>
                                                                    <DownloadSvg fill = {disable ? '#565A5E': '#05386B'}/> Download
                                                            </ButtonComponent>
                                                            </div>
                                                        </div>;
                                            })}
                                    </>
                                }
                            </div>
                    </div>
                </div>
            </LoadingOverlay>;
    };