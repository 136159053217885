import { ApplicationState } from "../../store/index";
import { Download } from "./Download";
import { connect } from 'react-redux';
import { actionCreators as SignerStore } from '../../store/Signing/SignerStore';
import { actionCreators as PublicStore } from '../../store/Common/PublicStore';
import { actionCreators as DownloadStore } from '../../store/Download/DownloadStore'; 
import { DownloadStep } from '../../Core/Common/Enums';
import { ISignFinishedResponse } from "src/Core/Domain/ViewModels/ISignFinishedResponse";

function mapStateToProps(state: ApplicationState) {
    return {
        signingStatus: state.signerData.SignatureStatus,
        documents: state.downloadData.documents,
        publicData: state.publicData,
        loaderData: state.loaderData
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string) => dispatch(PublicStore.requestPrimaryDetails(Id)),
        requestSignatureInfo: (clientGuid: string, callBack?: () => void) =>
            dispatch(SignerStore.requestSignatureInfo(clientGuid, callBack)),
        requestDocumentControls: (clientGuid: string, isControlsDisabled: boolean, skipTracking?: boolean) =>
            dispatch(SignerStore.requestDocumentControls(clientGuid, isControlsDisabled, skipTracking)),
        declineSigning: (clientGuid: string, remarks: string, callback: (status: boolean) => void) => dispatch(SignerStore.declineSigning(clientGuid, remarks, callback)),
        sign: (signatureData: any, callback: (status: ISignFinishedResponse) => void) => dispatch(SignerStore.sign(signatureData, callback)),
        requestDocuments: (clientGuid: string) => dispatch(DownloadStore.requestDocuments(clientGuid)),
        downloadDocument: (clientGuid: string, documentGuid: string, fileName: string) =>
            dispatch(DownloadStore.downloadDocument(clientGuid, documentGuid, fileName)),
        downloadAuditFile: (clientGuid: string, fileName: string) => dispatch(DownloadStore.downloadAuditFile(clientGuid, fileName)),
        downloadAllDocument: (clientGuid: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep) =>
            dispatch(DownloadStore.downloadAllDocument(clientGuid, fileName, isSigningCompleted, downloadStep))
        
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Download);
