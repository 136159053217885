export class EsignStrings {
    public static readonly SIGNATURE_VALIDATION_ERROR_MESSAGE = "Signature not found !!";
    public static readonly COMPANY_VALIDATION_ERROR_MESSAGE = "Company name not found !!";
    public static readonly DATE_VALIDATION_ERROR_MESSAGE = "Date not found !!";
    public static readonly INITIAL_VALIDATION_ERROR_MESSAGE = "Initial not found !!";
    public static readonly NAME_AND_TITLE_VALIDATION_ERROR_MESSAGE = "Name and Title not found !!";
    public static readonly NAME_VALIDATION_ERROR_MESSAGE = "Name not found !!";
    public static readonly TITLE_VALIDATION_ERROR_MESSAGE = "Title not found !!";
    public static readonly CUSTOM_TEXTBOX_VALIDATION_ERROR_MESSAGE = "Some custom field data not found !!";
    public static readonly CHECKBOX_VALIDATION_ERROR_MESSAGE = "Checkbox not found !!";
}
