import { ICompanyModel } from './ICompanyModel';

export interface IPrimaryDetails {
    companyData: ICompanyModel;
    IsOtpRequired: boolean;
    isMobileView: boolean;
    senderName: string;
    sender: IUserModel;
    clientName: string;
    loggedInClient: string;
    authenticationInfo: IAuthenticationInfo;
    maskedMobileNumber: string;
    signerMobileUrl: string;
    contactPerson: IUserModel;
    isDelegated: boolean;
}

export interface IUserModel {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    countryCode: string;
    emailAddress: string;
    mobileNumber: string;
}

export enum AuthenticationType {
    None = 0,
    AccessCodeEmail = 1,
    AccessCodeSMS = 2
}

export interface IAuthenticationInfo {
    email: string;
    AuthenticationType: AuthenticationType;
    IsLocked: boolean;
}

export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface ErrorState{
    error: boolean;
    attemptsLeft: number;
}
