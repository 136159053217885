import { ControlBaseProps, ControlData, SignatureData, TextBoxData, CheckBoxData, RadioButton } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import {
    CompanyControl, CustomTextControl, IControl, InitialControl, ISignatureData, SignatureControl, TextData, CheckBoxData as SFCheckBoxData,
    NameAndTitleControl, NameControl, TitleControl, CheckBoxControl, PrintNameControl, RadioControl, RadioGroupData
} from '../../../Core/Domain/Models/Controls';
import { IDocument } from '../../../Core/Domain/ViewModels/Document';
import * as Enumerable from 'linq';
import RadioButtonControl from 'awesome-pdf-viewer/dist/Controls/RadioButtonControl/RadioButtonControl';

export class PdfViewManager {

    public static updateControlData(documents: IDocument[], controlData: ControlData, controlProps: ControlBaseProps): IDocument[] {

        for (var i = 0; i <= documents.length - 1; i++) { // doument loop

            const pages = documents[i].pages;

            for (var j = 0; j <= pages.length - 1; j++) { // document pages loop

                const controls = pages[j].controls;

                for (var k = 0; k <= controls.length - 1; k++) { // page controls loop

                    const control = controls[k];

                    this.update(control, controlData, controlProps);

                }
            }
        }

        return documents;
    }



    public static highlightSelectedFile(fileId: string) {

        const currentfileitem: any = document.getElementsByClassName("file-node-selected")[0];
        currentfileitem && currentfileitem.classList.remove("file-node-selected");

        const nextFileitem: any = document.getElementsByClassName("file-node-" + fileId)[0];
        nextFileitem && nextFileitem.classList.add("file-node-selected");
    }

    public static getControlValueUpdatedPercentage(documents: IDocument[]): number {

        try {

            let total: number = 0;
            let controlsWithValue: number = 0;

            for (var i = 0; i <= documents.length - 1; i++) { // doument loop

                const pages = documents[i].pages;

                for (var j = 0; j <= pages.length - 1; j++) { // document pages loop

                    const controls = pages[j].controls;

                    total = total + Enumerable.from(controls).count(x => x.required == true);
                    if (total == 0) { // check if  all the controls are optional
                        continue;
                    }

                    const tmpControlsWithValues = Enumerable.from(controls)
                        .count((x: any) => x.required == true &&
                            x.data && (x.data["text"] || x.data["signature"] || x.data["checked"] ||
                                (x.data?.items && Enumerable.from(x.data.items).any((y: any) => y.value.checked))))
                    controlsWithValue = controlsWithValue + tmpControlsWithValues;
                }
            }

            if (total > 0) {
                return (controlsWithValue / total) * 100;
            }
            else {
                return 100;  // check if  all the controls are optional
            }               
        }
        catch(error) {
            
        }

        return 0;

    }

    private static update(control: IControl, controlData: ControlData, controlProps: ControlBaseProps) {

        if ((control instanceof SignatureControl && controlProps.data["controlType"] == 1)
            || (control instanceof InitialControl && controlProps.data["controlType"] == 2)) {
            if (control.id == controlProps.data["controlId"] || control.data != undefined) { // add signature to the particluar control or update already signed controls
                const tmpSignatureData: ISignatureData = SignatureData.create(
                    (controlData as SignatureData).name,
                    (controlData as SignatureData).signature,
                    (controlData as SignatureData).signatureMode);
                control.data = tmpSignatureData;
            }
        }
        else if ((control instanceof InitialControl && controlProps.data["controlType"] == 2)
            || (control instanceof NameAndTitleControl && controlProps.data["controlType"] == 4)
            || (control instanceof CompanyControl && controlProps.data["controlType"] == 5)
            || (control instanceof NameControl && controlProps.data["controlType"] == 7)
            || (control instanceof TitleControl && controlProps.data["controlType"] == 8)
            || (control instanceof PrintNameControl && controlProps.data["controlType"] == 10)) {

            const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || "");
            control.data = tmpTextData;

        }
        else if ((control instanceof CheckBoxControl && controlProps.data["controlType"] == 9)) {
            if (control.id == controlProps.data["controlId"]) {
                const tmpCheckBoxData: SFCheckBoxData = SFCheckBoxData.create((controlData as SFCheckBoxData).checked || false);
                control.data = tmpCheckBoxData;
            }
        }
        else if ((control instanceof RadioControl && controlProps.data["controlType"] == 11)) {
            if (control.id == controlProps.data["controlId"]) {
                const tmpRadioData: RadioGroupData = RadioGroupData.create(controlData as RadioButton[]);
                control.data = tmpRadioData;
                this.updateRadioControlValue(control, tmpRadioData);
            }
        }
        else if ((control instanceof CustomTextControl)) {

            if (control.id == controlProps.data["controlId"]) {

                const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || "");
                control.data = tmpTextData;

            }
        }
    }

    private static updateRadioControlValue(control: IControl, radioData: RadioGroupData) {
        if (control instanceof RadioControl) {
            control.items.forEach((item: RadioButton) => {
                const radioGroup = radioData.items.find(data => data.id === item.id);

                if (radioGroup) {
                    item.value = radioGroup.value;
                }
            })
        }
    }

}