import { ControlBaseProps, ControlData } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import * as Enumerable from 'linq';
import * as React from 'react';
import { IControl } from '../../../Core/Domain/Models/Controls';
import { Document, IDocument } from '../../../Core/Domain/ViewModels/Document';
import { FileList } from '../../Esign/FileList/FileList';
import { PdfViewManager } from './PdfViewBase';
import { Viewer } from './Viewer';
import { EMPTY_STRING, isStringNullOrEmpty } from 'src/components/Helper/HelperFunctions';


export interface PdfViewProps {
    documents: IDocument[];
    onSigningCompleted(): void;
    downloadInitialDocuments(): void;
    AssignToDelegateeSigner: boolean;
    disableNextButton: (value: boolean) => void;
}

export interface PdfViewState {
    documents: IDocument[];
    document: IDocument;
    startNavigationOnDocumentLoad: boolean;
    progress: number;
}


export class PdfView extends React.Component<PdfViewProps, PdfViewState> {

    private _viewer: any;

    constructor(props: any) {

        super(props);

        this.state = {
            documents: [],
            startNavigationOnDocumentLoad: false,
            document: Document.createNullObject(),
            progress: 0
        }
    }

    static getDerivedStateFromProps(nextProps: PdfViewProps, nextState: PdfViewState) {
        if (nextProps.documents.length > 0 && nextState.document) {

            let document: IDocument = Document.createNullObject();

            if (!isStringNullOrEmpty(nextState.document.fileId)) {
                document = nextState.document;
            }
            else {
                document = nextProps.documents.filter(x => x.disabled == false
                    && x.pages.some(y => y.controls.length > 0))[0];

                if (!document) {

                    document = nextProps.documents.filter(x => x.disabled == false)[0];
                }
            }
      
            return {
                documents: nextProps.documents,
                document: document,
                progress: PdfViewManager.getControlValueUpdatedPercentage(nextProps.documents)
            };

        }
        else return { documents: nextProps.documents, document: Document.createNullObject() };
    }

    componentDidUpdate(prevProps: PdfViewProps, prevState: PdfViewState) {
        if (this.state.progress >= 100 && prevState.progress != this.state.progress) {
            this.props.onSigningCompleted();
        } 
    }

    shouldComponentUpdate(nextProps: PdfViewProps, nextState: PdfViewState) {  
        if (nextProps.documents.length > 0 && this.props.documents.length !== nextProps.documents.length
            || (!isStringNullOrEmpty(this.state.document.fileId) && this.state.document.fileId !== nextState.document.fileId) || this.state.progress !== nextState.progress) {
            return true;
        }
        return false;
    }

    handleFileClick = (document: IDocument) => {
        this.setState({ document: document, startNavigationOnDocumentLoad: true });
    }

    handleNavigationFinish = () => {
        this.setDocument();
    }

    public getDocumentsData = (): IDocument[] => {
        return this.state.documents;
    }

    public showValidationMessage = (document: IDocument, page: number, control: IControl) => {

        this.setState({ document: document }, () => {
            this._viewer.showValidationMessage(page, control);
        });

    }

    private setDocument = () => {

        if (!isStringNullOrEmpty(this.state.document.fileId)) {

            const documents = this.state.documents.filter(x => x.disabled == false
                && x.pages.some(y => y.controls.length > 0));

            // set next document using index
            const nextDocumentIndex = documents.indexOf(this.state.document) + 1;

            const nextDocument: IDocument | undefined = Enumerable.from(documents).elementAtOrDefault(nextDocumentIndex);

            if (nextDocument == undefined) {
                return;
            }

            if (documents.length == nextDocumentIndex + 1) {
                this.setLastDocument(nextDocument);
            }
            else {
                this.setNextDocument(nextDocument);
            }
        }
        else {
            this.setFirstDocument();
        }
    }

    private setFirstDocument = () => {
        this.setState({ document: this.state.documents[0] });
    }

    private setNextDocument = (nextDocument: IDocument) => {

        this.setState({
            document: nextDocument,
            startNavigationOnDocumentLoad: true
        });
    }

    private setLastDocument = (nextDocument: IDocument) => {
        this.setState({ document: nextDocument, startNavigationOnDocumentLoad: true });
    }

    private isLastDocument = (document: IDocument) => {

        const documents = this.state.documents.filter(x => x.disabled == false
            && x.pages.some(y => y.controls.length > 0));

        // set next document using index
        const nextDocumentIndex = documents.indexOf(document) + 1;

        const nextDocument: IDocument | undefined = Enumerable.from(documents).elementAtOrDefault(nextDocumentIndex);

        if (nextDocument == undefined) {
            return true
        }

        return false;
    }

    private updateControlData = (controlData: ControlData, controlProps: ControlBaseProps) => {
        const { documents } = this.state;
        PdfViewManager.updateControlData(documents, controlData, controlProps);

        const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(documents);
        completedPercentage >= 0 && this.setState({ progress: completedPercentage }, () => {
            completedPercentage >= 100 ? this.props.onSigningCompleted() : this.props.disableNextButton(true);
        });
    }

    public render() {

        const { documents, document } = this.state;

        const finishTarget: string = this.isLastDocument(document) ? "btnContinue" : "";

        return (<div className="pdf-preview-container">
            <Viewer ref={(ref) => this._viewer = ref}
                pages={document.pages}
                url={document.fileLink}
                progress={this.state.progress}
                fileList={<FileList selectedDocumentId={document ? document.fileId : EMPTY_STRING} documents={documents} onFileClick={this.handleFileClick} />}
                startNavigationOnDocumentLoad={this.state.startNavigationOnDocumentLoad}
                finishTarget={finishTarget}
                onNavigationFinish={this.handleNavigationFinish}
                onAddControlData={this.updateControlData}
                downloadInitialDocuments={this.props.downloadInitialDocuments}
                AssignToDelegateeSigner={this.props.AssignToDelegateeSigner}
            />
        </div>);

    }
}
