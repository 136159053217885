import { ICustomData } from './Controls'
import { SignatureControlRole } from '../ViewModels/DocumentViewModel';

export interface ISignerInfo extends ICustomData {
    email: string;
}

export interface IControlRole extends ICustomData {
    role: SignatureControlRole;
}

export class SignerInfo implements ISignerInfo {
    email: string;

    constructor(email: string) {
        this.email = email;
    }

    static create(email: string): ISignerInfo {
        return new SignerInfo(email);
    }

}

export class ControlRole implements IControlRole {
    role: SignatureControlRole;

    constructor(role: SignatureControlRole) {
        this.role = role;
    }

    static create(role: SignatureControlRole): IControlRole {
        return new ControlRole(role);
    }

}