import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from './NotificationStore';
import { ICountryState, IPrimaryDetails } from '../../Core/Domain/ViewModels/PrimaryDetails'
import { HttpAction } from './LoaderStore'
import { axiosFetch, storeTokenInMemory, inMemoryToken } from '../../Core/Services/DataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { IResponseNotifier } from '../../Core/Domain/ViewModels/IResponseNotifier';
import { NotificationType } from '../../Core/Common/Enums';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ITokenData } from '../Account/OtpStore';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { ICompanyLogo } from '../../Core/Domain/ViewModels/ICompanyModel';
const logger = new LoggerFactory().getTelemetryLogger();

export interface IPublicData {
    primaryDetails: IPrimaryDetails;
    countryStates: ICountryState[];
    companyLogo: ICompanyLogo;
    isLoading: boolean;
}

export interface IPublicUserTokenData extends ITokenData {
    isLoading: boolean;
}

interface RequestPrimaryInfoAction {
    type: actionTypes.REQUEST_PRIMARY_INFO;
    isLoading: boolean;
}

interface RecieveCompanyLogoAction { 
    type: actionTypes.RECEIVE_COMPANY_LOGO;
    isCompanyLogo: boolean;
    companyLogoPath: string;
}

interface ReceivePrimaryInfoAction {
    type: actionTypes.RECEIVE_PRIMARY_INFO;
    primaryDetails: IPrimaryDetails;
}

interface ReceiveAllCountryStateAction {
    type: actionTypes.RECEIVE_COUNTRY_STATES;
    states: ICountryState[]
}

interface RequestPublicUserToken {
    type: actionTypes.REQUEST_PUBLIC_USER_ACCESSTOKEN;
    isLoading: boolean;
}

interface ReceivePublicUserToken {
    type: actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN;
    accessToken: string;
}

interface UpdateLockStateAction {
    type: actionTypes.UPDATE_LOCK_STATE;
    isLocked: boolean;
}

type KnownAction =
    DispatchAction |
    HttpAction |
    NotificationAction;

type DispatchAction = RequestPrimaryInfoAction | ReceivePrimaryInfoAction | RequestPublicUserToken | ReceivePublicUserToken |
    UpdateLockStateAction | ReceiveAllCountryStateAction | RecieveCompanyLogoAction;

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export const actionCreators = {

    requestPrimaryDetails: (Id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        dispatch({ type: actionTypes.REQUEST_PRIMARY_INFO, isLoading: true })
        
        return axiosFetch(Id).get<IPublicData>('api/Public/GetPrimaryDetails/' + Id)
            .then(function (response: AxiosResponse) {
                const { data } = response;
                if (data.isMobileView) {
                    window.location.href = data.signerMobile + 'login/' + Id;
                    return;
                }
                if (data.authenticationInfo) {
                    dispatch({
                        type: actionTypes.RECEIVE_PRIMARY_INFO, primaryDetails: {
                            companyData: { CompanyId: data?.company?.companyId, CompanyName: data?.company?.companyName },
                            IsOtpRequired: data?.isOtpRequired, 
                            isMobileView: data?.isMobileView, 
                            loggedInClient: data?.loggedInClient,
                            senderName: data?.senderName, 
                            clientName: data?.clientName, 
                            sender: data?.sender,
                            authenticationInfo: data?.authenticationInfo,
                            maskedMobileNumber: data?.maskedMobileNumber,
                            signerMobileUrl:data?.signerMobileUrl,
                            contactPerson: data?.sender,
                            isDelegated: data?.isDelegated
                        },
                    });
                    window.Variables = {sessionTimeout : data.sessionIdleTimeOut};
                    dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                }
                else {
                    localStore.remove('loggedIn');
                    window.location.href = '/invalid';
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error?.response?.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`requestPrimaryDetails failed for the request having Id ${Id} with error ${error.message}`)
            });
    },

    getTokenForAnonymous: (Id: string, callback: (clientId: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_PUBLIC_USER_ACCESSTOKEN, isLoading: true });
        return axiosFetch().get<IResponseNotifier>('api/Anonymous/GenerateToken/' + Id)
            .then(function (response: AxiosResponse<IResponseNotifier>) {
                const result: IResponseNotifier = response.data;
                if (result.Type === NotificationType.Success) {
                    dispatch({
                        type: actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN, accessToken: result.Data.Token
                    });
                    localStore.set('loggedIn', true);
                    storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
                    callback(result.Data.ClientGuid);
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: result.Message,
                        statusType: StatusType.Error
                    });
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`getTokenForAnonymous failed for the request having Id ${Id} with error ${error.message}`)
            });
    },
    getAllStates: (Id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return axiosFetch().get<IPublicData>('api/Public/GetAllStates/' + Id)
            .then(function (response: AxiosResponse<ICountryState[]>) {
                dispatch({
                    type: actionTypes.RECEIVE_COUNTRY_STATES, states: response.data
                });
            })
            .catch({

            })
    },

    dispatchUserToken: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (localStore.get('loggedIn')) {
            const publicUserToken = inMemoryToken.get(clientId);
            dispatch({
                type: actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN, accessToken: publicUserToken != undefined ? publicUserToken : ""
            });
        }
    },
    updateLockState: (isLocked: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATE_LOCK_STATE, isLocked: isLocked
        });
    },
    requestCompanyLogo: (Id: string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return axiosFetch(Id).get<boolean>('api/public/GetCompanyLogoLinkAsync/' + Id)
        .then(function (response: AxiosResponse<any>) {
            const { data } = response;
            if (data) {
                dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO, isCompanyLogo: data?.isCompanyLogo, companyLogoPath: data?.logoPath });
            }
        })
        .catch(function (error: any) {
            dispatch({
                type: actionTypes.NOTIFICATION, statusMessage: error,
                statusType: StatusType.Error
            });
        });
    }
};

const unloadedprimaryInfoState: IPublicData = {
    primaryDetails: {},
    companyLogo: {
        isCompanyLogo: false,
        companyLogoPath: ""
    },
} as IPublicData;

const unloadedTokenState: IPublicUserTokenData = {
    token: "",
    isLoading: false
} as IPublicUserTokenData;

export const publicReducer: Reducer<IPublicData> = (state: IPublicData = unloadedprimaryInfoState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.REQUEST_PRIMARY_INFO:
            let newState = {...state}
            return {
                ...newState,
                primaryDetails: {} as IPrimaryDetails,
                countryStates:[],
                isLoading: action.isLoading,                
            };
        case actionTypes.RECEIVE_PRIMARY_INFO:
            data.isLoading = false;
            data.primaryDetails = action.primaryDetails;
            return data;
        case actionTypes.RECEIVE_COUNTRY_STATES:
            data.countryStates = action.states;
            return data;
        case actionTypes.UPDATE_LOCK_STATE:
            data.primaryDetails.authenticationInfo.IsLocked = action.isLocked;
            return data;
        case actionTypes.RECEIVE_COMPANY_LOGO:
            data.companyLogo.isCompanyLogo = action.isCompanyLogo;
            data.companyLogo.companyLogoPath = action.companyLogoPath;
            return data;    
        default:
            return state || unloadedprimaryInfoState;
    }
};

export const tokenReducer: Reducer<IPublicUserTokenData> = (state: IPublicUserTokenData = unloadedTokenState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_PUBLIC_USER_ACCESSTOKEN:
            return {
                token: "",
                isLoading: action.isLoading
            } as IPublicUserTokenData;
        case actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN:
            return {
                token: action.accessToken,
                isLoading: false
            } as IPublicUserTokenData;
        default:
            return state || unloadedTokenState;
    }
};