import * as React from 'react';
import { Modal} from 'react-bootstrap';
import { Countdown } from '../Helper/Countdown';
import { History } from 'history';
import { inMemoryToken } from '../../Core/Services/DataAccess/DataService.Axios';
import { match } from 'react-router';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { ButtonComponent } from 'cp-common-ui-components';

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

declare global {
    interface Window {
        Variables: any;
    }
}

const WarningVisibleMinutes = 1;

interface ISessionTimeoutProps {
    history: History,
    match: match,
    refreshToken(clientId: string, callback: () => void): void,
    dispatchUserToken(clientId: string): void,
}

export const SessionTimeout: React.FC<ISessionTimeoutProps> = (props) => {

    let countDown: Countdown = undefined;
    const [showTimeout, setShowTimeout] = React.useState(false);

    React.useEffect(() => {
        let param: any = props.match.params;
        if (!inMemoryToken.has(param.clientId)) {
            props.refreshToken(param.clientId, setExpiryTime);
        }
        else {
            props.dispatchUserToken(param.clientId);
            setExpiryTime();
        }
    }, []);

    const  getSessionIdleMinutes = () =>{
        if(window.Variables !== undefined){
            return window.Variables.sessionTimeout;
        }
        return 20;
    }

    const setExpiryTime = () => {
        window.setTimeout(() => { setShowTimeout(true) }, 1000 * 60 * (getSessionIdleMinutes() - WarningVisibleMinutes));
    }

    const onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        if (countDown) {
            clearInterval(countDown.interval);
        }
        let param: any = props.match.params;
        props.refreshToken(param.clientId, setExpiryTime);
        setShowTimeout(false);
    }

    const onLogout = () => {
        let param: any  = props.match.params;
        localStore.remove('loggedIn');
        inMemoryToken.delete(param.clientId);
        props.history.push('/logout/' + param.clientId);
    }

   
    const getCountDown = (): Date => {
        const now = new Date();
        let ret = now;
        ret.setMinutes(now.getMinutes() + WarningVisibleMinutes);
        return ret;
    }
    

        return <Modal
            className="session-timeout-modal"
            show={showTimeout}
            onHide={onLogout}
            backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Session Expiration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div style={{margin:'10px 0px 20px 0px'}}>Your session will expire in <Countdown
                        date={getCountDown()}
                        onComplete={onLogout}
                        ref={(instance: any) => { countDown = instance }} />
                    </div>
                    <div style={{marginBottom: '7px'}}>
                         Do you want to continue working?
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                        size="medium"
                        variant="outline-tertiary"
                        onClick={onLogout}
                        >
                    Log Out
                </ButtonComponent>
                <ButtonComponent
                        size="medium"
                        variant="primary"
                        onClick={onStayAlive}
                        >
                    Continue Working
                </ButtonComponent>
            </Modal.Footer>
        </Modal>
}