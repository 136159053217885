import * as React from 'react';
import { match } from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import { History } from 'history'
import { inMemoryToken } from '../../../Core/Services/DataAccess/DataService.Axios'
import { TYPES } from '../../../Startup/types';
import { container } from '../../../Startup/inversify.config';
import { ILocalStore } from '../../../Core/Utilities/LocalStore';
import { LogoutPopover } from './LogoutPopover';
import {hideFinishButton} from "../../Helper/HelperFunctions"

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export type LogoutProps =
    {
        match?: match;
        history?: History;
    }

export interface ILogoutState {
    showDialog: boolean
}

export class Logout extends React.Component<LogoutProps, ILogoutState> {

    showSignOutbtn: boolean = true;
    constructor(props: any) {
        super(props);

        this.state = {
            showDialog: false
        }
    }

    private showDialogBox = () => {
        this.setState({ showDialog: true });
    }

    private hideDialogBox = () => {
        this.setState({ showDialog: false });
    }

    private onLogout = (e: any) => {
        if (this.props.match && this.props.history) {
            let param: any = this.props.match.params;
            hideFinishButton();
            inMemoryToken.delete(param.clientId);
            localStore.remove('loggedIn');
            this.props.history.push('/logout/' + param.clientId);           
        }
    }

    public render() {
        this.showSignOutbtn = !this.props?.match?.url?.includes('login');

        const popover = <LogoutPopover
            showDialog={this.state.showDialog}
            onCancel={this.hideDialogBox}
            onLogout={this.onLogout} />;
        return (
            
            <div className="logout-btn-container">

                {this.showSignOutbtn ? <Button onClick={this.showDialogBox} className="btn btn-sm">Log Out</Button> : ''}

                {this.state.showDialog ? <div className="logout-confirmation"> {popover} </div> : <br />}
            </div>
        );
    }
}
